import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import apiRequest from "api/apiRequest";
import urls from "api/urls";
import { FamiliesMoreAboutUs } from "./components/FamiliesMoreAboutUs/FamiliesMoreAboutUs";
import { useParams } from "react-router-dom";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import ConfigurationContext from "contexts/ConfigurationContext";
import { FamiliesBioAndFavoriteMemory } from "./components/FamiliesBioAndFavoriteMemory/FamiliesBioAndFavoriteMemory";
import { FamiliesProfileActivities } from "./components/FamiliesProfileActivities/FamiliesProfileActivities";
import FamiliesProfileAlbum from "./components/FamiliesProfileAlbum/FamiliesProfileAlbum";
import FamilyProfileDetails from "./components/FamilyProfileDetails/FamilyProfileDetails";

export const FamilyNetworkProfileDetails = () => {
  const { type, userId } = useParams();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);

  useEffect(() => {
    apiRequest({
      url:
        type === "ambassador"
          ? `${urls.ambassadors}/${userId}`
          : `${urls.families}/${userId}`,
    }).then((resp) => {
      setProfile(resp.data);
      setIsLoading(false);
    });
  }, [type, userId]);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <React.Fragment>
          <Box p={{ xs: 2 }}>
            <FamilyProfileDetails profile={profile} type={type} />
            <FamiliesBioAndFavoriteMemory profile={profile} type={type} />

            {isFeatureEnabled("family_albums") && (
              <FamiliesProfileAlbum profile={profile} type={type} />
            )}
            {isFeatureEnabled("family_activities") && (
              <FamiliesProfileActivities profile={profile} type={type} />
            )}
          </Box>
          <FamiliesMoreAboutUs profile={profile} type={type} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FamilyNetworkProfileDetails;
